import { getFullMonthName, getWeekDays } from '../../../../util/dateHelper';
import { HalfDayTypes } from './Month';

const newStyling = document.body.classList.contains('homepage-search-feature');

export type dayTemplateVariable = {
    id: string | null;
    dateString: string;
    day: number;
    current: boolean;
    available: boolean;
    disabled: boolean;
    blocked: boolean;
    synced: boolean;
    booked: boolean;
    selected: boolean;
    selectStart: boolean;
    selectEnd: boolean;
    selectRange: boolean;
    configured: boolean;
    firstDayType: string;
    lastDayType: string;
};

export default function monthTemplate(month: number, year: number): string {
    const headerClass = newStyling ? 'nh-calendar-month__header' : 'calendar-month-header month';
    const weekdaysClass = newStyling ? 'nh-calendar-month__weekdays' : 'week-days';
    const dayOfWeekClass = newStyling ? 'nh-calendar-day-of-week' : '';
    const daysClass = newStyling ? 'nh-calendar-month__dates' : 'days';

    const locale = document.documentElement.dataset.locale
        ? document.documentElement.dataset.locale.replace('_', '-')
        : 'default';

    return `
        <header class="${headerClass}">${getFullMonthName(
        new Date(year, month - 1),
        locale
    )}</header>
        <ol class="${weekdaysClass}">${getWeekDays(locale)
        .map((day) => `<li class="${dayOfWeekClass}">${day}</li>`)
        .join('')}</ol>
        <ol class="${daysClass}" data-days></ol>
    `;
}

export function generateDayElement(day: dayTemplateVariable): HTMLElement {
    const dayClass = newStyling ? 'nh-calendar-day' : 'day';
    const spanClass = newStyling ? 'nh-calendar-day__date' : '';

    const dayElement = document.createElement('LI');
    const dayString = day.day.toString();
    dayElement.classList.add(dayClass);
    dayElement.dataset.day = dayString;
    dayElement.dataset.id = day.id || '345';

    dayElement.innerHTML = `<span class="day__day-string ${spanClass}">${dayString}</span>`;
    return dayElement;
}

export function setDayProperties(dayElement: HTMLElement, day: dayTemplateVariable): HTMLElement {
    const isFullDay: boolean =
        day.firstDayType === HalfDayTypes.NONE && day.lastDayType === HalfDayTypes.NONE;

    dayElement.dataset.date = day.dateString;
    dayElement.classList.toggle('day--today', day.current);
    dayElement.classList.toggle('day--booked', day.booked && isFullDay);
    dayElement.classList.toggle('day--available', day.available);
    dayElement.classList.toggle(
        'day--blocked',
        day.blocked && day.lastDayType !== HalfDayTypes.BOOKED && !day.booked
    );
    dayElement.classList.toggle('day--synced', day.synced);
    dayElement.classList.toggle('day--configured', day.configured);

    if (!newStyling) {
        dayElement.classList.toggle('day--selected', day.selected);
        dayElement.classList.toggle('day--disabled', day.disabled && !day.selected);
    }

    if (newStyling) {
        dayElement.classList.toggle('nh-calendar-day--select-date', day.selected);
        dayElement.classList.toggle('nh-calendar-day--select-start', day.selectStart);
        dayElement.classList.toggle('nh-calendar-day--select-end', day.selectEnd);
        dayElement.classList.toggle('nh-calendar-day--select-range', day.selectRange);
        dayElement.classList.toggle('nh-calendar-day--disabled', day.disabled);
    }

    dayElement.classList.toggle(
        'day--is-first-day--booked',
        day.firstDayType === HalfDayTypes.BOOKED
    );
    dayElement.classList.toggle(
        'day--is-last-day--booked',
        day.lastDayType === HalfDayTypes.BOOKED
    );

    dayElement.dataset.disabled = day.disabled ? '1' : '';
    dayElement.dataset.booked = day.booked ? '1' : '';
    dayElement.dataset.firstDay = day.firstDayType === HalfDayTypes.BOOKED ? '1' : '';
    dayElement.dataset.lastDay = day.lastDayType === HalfDayTypes.BOOKED ? '1' : '';
    dayElement.dataset.available = day.available ? '1' : '';
    dayElement.dataset.blocked = day.blocked && !day.booked ? '1' : '';
    dayElement.dataset.configured = day.configured ? '1' : '';
    dayElement.dataset.id = day.id || '';

    return dayElement;
}

import { isDateValid } from '@naturehouse/nh-essentials/lib/dates/date';
import {
    getCurrentMonthNumber,
    getCurrentYear,
    getMonths,
    getYears
} from '../../../util/dateHelper';

import './styles.pcss';

import Month from './month/Month';

export default class CalendarBase extends HTMLElement {
    #months: Month[] = [];

    #start: Date = new Date(getCurrentYear(), getCurrentMonthNumber() - 1);

    #end: Date = new Date(getCurrentYear(), getCurrentMonthNumber() + 11, 0);

    constructor() {
        super();

        const startDate: string | null = this.getAttribute('start');
        if (startDate && isDateValid(new Date(startDate))) {
            this.#start = new Date(startDate);
        }

        const endDate: string | null = this.getAttribute('end');
        if (endDate && isDateValid(new Date(endDate))) {
            this.#end = new Date(endDate);
        }
    }

    get start(): Date {
        return this.#start;
    }

    set start(date: Date) {
        this.#start = date;
    }

    get end(): Date {
        return this.#end;
    }

    set end(date: Date) {
        this.#end = date;
    }

    get months(): Month[] {
        return this.#months;
    }

    protected connectedCallback(): void {
        if (!this.#months.length) {
            this.#months = this.#initMonths(this.#start, this.#end);
        }

        const header = this.querySelector('.calendar-header');
        const footer = this.querySelector('.calendar-footer');

        this.innerHTML = `
            ${header ? header.outerHTML : ''}
            <div class="calendar-base"></div>
            ${footer ? footer.outerHTML : ''}
        `;

        const base = this.querySelector('.calendar-base') as HTMLElement;
        base.append(...this.#months);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected handleDayClick(event: CustomEvent): void {
        /* Stub */
    }

    protected showMoreMonths(amount: number): void {
        const calendarBase = this.querySelector('.calendar-base');
        if (calendarBase === null) {
            return;
        }

        for (let index = 0; index < amount; index++) {
            const monthNumber = index + 1;
            const month = new Date(this.end.getFullYear(), this.end.getMonth() + monthNumber);
            const monthElement = this.createMonthElement(month.getFullYear(), month.getMonth() + 1);
            this.#months.push(monthElement);
            calendarBase.appendChild(monthElement);
        }

        this.end = new Date(this.end.getFullYear(), this.end.getMonth() + amount);
    }

    protected addMonth(year: number, month: number): void {
        const calendarBase = this.querySelector('.calendar-base');
        if (calendarBase === null) {
            return;
        }

        const monthElement = this.createMonthElement(year, month);
        this.#months.push(monthElement);
        calendarBase.appendChild(monthElement);
    }

    protected createMonthElement(year: number, month: number): Month {
        const monthElement = new Month(year, month);

        monthElement.addEventListener('day-click', (event: Event): void => {
            const customEvent = event as CustomEvent;
            this.handleDayClick(customEvent);
        });

        return monthElement;
    }

    #initMonths(start: Date, end: Date): Month[] {
        const years: number[] = getYears(start.getFullYear(), end.getFullYear());

        const elements: Month[] = [];
        for (const year of years) {
            const months = getMonths(year, start, end);
            for (const month of months) {
                elements.push(this.createMonthElement(year, month));
            }
        }

        return elements;
    }
}

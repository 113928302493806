import iconTemplate from '@naturehouse/design-system/components/protons/icon/Icon.html.njk';
import parseStringAsHtml from '@naturehouse/nh-essentials/lib/html/parser';

import {
    formatDateForUrl,
    getCurrentMonthNumber,
    getCurrentYear,
    isSame
} from '../../../../util/dateHelper';
import monthTemplate, {
    dayTemplateVariable,
    generateDayElement,
    setDayProperties
} from './default.html';

type PeriodType = Period | BlockedPeriod | BookedPeriod | DateSelection;

export const CalendarDayEvents: StandardObjectInterface = {
    dayClick: 'day-click'
};

export enum HalfDayTypes {
    NONE = '',
    BOOKED = 'booked'
}

export default class Month extends HTMLElement {
    readonly #month: number;

    readonly #year: number;

    #days: HTMLElement[] = [];

    #available: Availability[] = [];

    #blocked: BlockedPeriod[] = [];

    #booked: BookedPeriod[] = [];

    #configured: Period[] = [];

    #selected: DateSelection[] = [];

    #defaultDisabled = false;

    constructor(year: number = getCurrentYear(), month: number = getCurrentMonthNumber()) {
        super();

        if (document.body.classList.contains('homepage-search-feature')) {
            this.classList.add('nh-calendar-month');
        }

        this.#month = Number(this.getAttribute('month')) || month;
        this.#year = Number(this.getAttribute('year')) || year;

        this.setAttribute('year', this.#year.toString());
        this.setAttribute('month', this.#month.toString());

        const blocked = this.getAttribute('blocked');
        const booked = this.getAttribute('booked');
        const configured = this.getAttribute('configured');

        if (blocked !== null) {
            this.#blocked = JSON.parse(blocked);
        }

        if (booked !== null) {
            this.#booked = JSON.parse(booked);
        }

        if (configured !== null) {
            this.#configured = JSON.parse(configured);
        }
    }

    set blocked(blocked: BlockedPeriod[]) {
        this.#blocked = blocked;
        this.update();
    }

    set available(available: Availability[]) {
        this.#available = available;
        this.update();
    }

    set booked(booked: BookedPeriod[]) {
        this.#booked = booked;
        this.update();
    }

    set configured(configured: Period[]) {
        this.#configured = configured;
        this.update();
    }

    set selected(selected: DateSelection[]) {
        this.#selected = selected;
        this.update();
    }

    set defaultDisabled(defaultDisabled: boolean) {
        this.#defaultDisabled = defaultDisabled;
    }

    protected connectedCallback(): void {
        const days: dayTemplateVariable[] = this.#calculateDays();
        this.#days = days.map((day) => {
            const element = generateDayElement(day);
            setDayProperties(element, day);
            element.addEventListener('click', () => {
                const detail = { day: element, month: this, dateString: day.dateString };
                const event = new CustomEvent(CalendarDayEvents.dayClick, { detail: detail });
                this.dispatchEvent(event);
            });
            return element;
        });

        this.#render();
    }

    private update(): void {
        const days: dayTemplateVariable[] = this.#calculateDays();

        this.#days.forEach((dayElement) => {
            const day = days.find((d) => d.dateString === dayElement.dataset.date);
            if (!day) {
                return;
            }

            setDayProperties(dayElement, day);
            const icon = dayElement.querySelector('.nh-icon');

            if (icon === null && !day.synced) return;
            if (icon !== null && !day.synced) {
                icon.remove();
                return;
            }

            if (icon === null && day.synced) {
                const iconElement = parseStringAsHtml(
                    iconTemplate.render({
                        name: 'share',
                        size: '0.625'
                    }),
                    '.nh-icon'
                );

                dayElement.append(iconElement);
            }
        });
    }

    #calculateDays(): dayTemplateVariable[] {
        const days: dayTemplateVariable[] = [];
        const now: Date = new Date();
        const start: Date = new Date(this.#year, this.#month - 1, 1);
        const end: Date = new Date(this.#year, this.#month, 0);

        now.setHours(0, 0, 0, 0);

        for (let day: number = start.getDate(); day <= end.getDate(); day++) {
            const date: Date = new Date(start.getFullYear(), start.getMonth(), day);
            const dayConfig: dayTemplateVariable = this.#getDayConfig(date, now);
            days.push(dayConfig as dayTemplateVariable);
        }

        return days;
    }

    #getDayConfig(date: Date, now: Date): dayTemplateVariable {
        const dateString: string = formatDateForUrl(date);
        const availability: Availability | null = this.#findDateInAvailability(
            this.#available,
            dateString
        );
        const block: BlockedPeriod | null = this.#findPeriodForDate(
            this.#blocked,
            dateString
        ) as BlockedPeriod | null;
        const booking: BookedPeriod | null = this.#findPeriodForDate(
            this.#booked,
            dateString
        ) as BookedPeriod | null;
        const period: Period | null =
            (this.#findPeriodForDate(this.#configured, dateString) as Period) || null;
        const selection: PeriodType | null = this.#findPeriodForDate(this.#selected, dateString);
        const isBookedFirstDay: boolean =
            booking !== null ? isSame(new Date(booking.start), date) : false;
        const isBookedLastDay: boolean =
            booking !== null ? isSame(new Date(booking.end), date) : false;

        const selected = selection !== null;
        const selectRange = selected && dateString > selection.start && dateString < selection.end;
        const selectStart =
            selected && selection.start === dateString && selection.end !== dateString;
        const selectEnd =
            selected && selection.start !== dateString && selection.end === dateString;

        const storedPeriod = availability || booking || period || block;
        const available = availability !== null;

        return {
            id: storedPeriod?.id?.toString() || null,
            dateString: dateString,
            day: date.getDate(),
            current: date.getTime() === now.getTime(),
            disabled: date < now || (this.#defaultDisabled && !available),
            available: available,
            blocked: block !== null,
            synced: booking === null && block !== null && block.reason === 'synced',
            booked: booking !== null && !isBookedLastDay,
            selected,
            selectStart,
            selectEnd,
            selectRange,
            configured: period !== null,
            firstDayType: isBookedFirstDay ? HalfDayTypes.BOOKED : HalfDayTypes.NONE,
            lastDayType: isBookedLastDay && !block ? HalfDayTypes.BOOKED : HalfDayTypes.NONE
        };
    }

    #findPeriodForDate(periods: PeriodType[], date: string): PeriodType | null {
        const result: PeriodType | undefined = periods.find(
            (period: PeriodType) => date >= period.start && date <= period.end
        );
        return typeof result !== 'undefined' ? result : null;
    }

    #findDateInAvailability(availability: Availability[], date: string): Availability | null {
        const result = availability.find((a) => a.date === date);
        return result ?? null;
    }

    #render(): void {
        this.innerHTML = monthTemplate(this.#month, this.#year);
        const daysElement = this.querySelector('[data-days]') as HTMLElement;

        const firstDayOfMonth = new Date(this.#year, this.#month - 1).getDay();
        const amountOfEmptyDays = firstDayOfMonth === 0 ? 7 : firstDayOfMonth;

        for (let i = 1; i < amountOfEmptyDays; i++) {
            daysElement.appendChild(document.createElement('LI'));
        }

        daysElement.append(...this.#days);

        const lastDayOfMonth = new Date(this.#year, this.#month, 0).getDay();

        for (let i = lastDayOfMonth; i > 0 && i <= 6; i++) {
            daysElement.appendChild(document.createElement('LI'));
        }
    }
}

if (!customElements.get('calendar-month')) {
    customElements.define('calendar-month', Month);
}
